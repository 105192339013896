import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {aboutLinksTypes, menuItemTypes, products, productsData} from "./constants";

i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                translation: {
                    index: {
                        title: '"Hedgehog Reader" - an e-book reader',
                        familyTitle: '... and this is my older brother - ',
                        header: {
                            title: 'Hedgehog Reader',
                            slogan: 'An e-book reader for those who value their time',
                        },
                        menu:
                            [
                                {title: 'Download', type: menuItemTypes.download},
                                {title: 'Features', type: menuItemTypes.features},
                                {title: 'Screenshots', type: menuItemTypes.screenshots},
                                {title: 'Videos', type: menuItemTypes.videos},
                                {title: 'About us', type: menuItemTypes.about, newPage: true},
                                {title: 'Privacy policy', type: menuItemTypes.privacy, newPage: true},
                                {title: 'FB2 Genres', type: menuItemTypes.genres, newPage: true},
                                {title: 'Migration Guide', type: menuItemTypes.migrationGuide, newPage: true},
                            ],
                        download: {
                            install: 'To install the app read the QR-code with a standard camera of your iPhone or iPad...',
                            or: '...OR...',
                            visit: '...visit',
                            page: 'our page in Appstore'
                        },
                        features: [
                            'supports formats: FB2, EPUB, MOBI, RTF, DOCX, HTML, MHT/MHTML, Webarchive, CHM, TXT, CBZ (comic book ZIP) and PDF;<0>if PDF file has a text layer, the text will be interpreted and the book will be formatted as a text one. If not, it will be opened as a set of regular pictures, like it\'s done for comic books;</0>',
                            'can open audiobooks of MP3, M4A and M4B formats;',
                            'import from local devices, iCloud, Dropbox, servers connected by SMB protocol, as a single book and as a batch;',
                            'an ability to create internal shelves structure, with search/filtering by genres and keywords;',
                            'full-text search of all the library contents, together with the notes created;',
                            'an ability to translate selected words and phrases to the chosen language;',
                            'reading the text aloud with different variants of speed and operating from the media control;',
                            'an ability to correct typos quickly;',
                            'bookmarks and notes, ability to export the latter as a file;',
                            'an ability to directly download books from servers that support OPDS.'
                        ],
                        screenshots:
                            [
                                {
                                    text: 'Import screen',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.importer
                                },
                                {
                                    text: 'Shelf view (\'Tiles mode\')',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.shelf
                                },
                                {
                                    text: 'Page containing image',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.withImage
                                },
                                {
                                    text: 'Image expanded',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.withImageExpanded
                                },
                                {
                                    text: 'Page containing table',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.table
                                },
                                {
                                    text: 'Table expanded',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.tableExpanded
                                },
                                {
                                    text: 'Context menu on selected text',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.translate
                                },
                                {
                                    text: 'Translation popup',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.translated
                                },
                                {
                                    text: 'Context menu on the text with a note created',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.noteAndMenu
                                },
                                {
                                    text: 'Contents',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.contents
                                },
                                {
                                    text: 'Notes with menu buttons',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.notes
                                },
                                {
                                    text: 'Voice selection',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.voiceSelection
                                },
                                {
                                    text: 'In the process of reading aloud',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.readAloud
                                },
                                {
                                    text: 'Media control when reading aloud',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.mediaControl
                                },
                                {
                                    text: 'Audiobook',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.audioBook
                                },
                                {
                                    text: 'Listening audiobook',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.audioBookReading
                                },
                                {
                                    text: 'Audiobook settings',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.audioBookSettings
                                },
                                {
                                    text: 'Media control when listening audiobook',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.audioBookMediaControl
                                },

                            ],
                    },
                    privacyPolicy: {
                        title: 'Hedgehog Reader\'s <br/>Privacy Policy',
                        paragraphs: [
                            {
                                header: 'Collection and Use of Personal Information',
                                text: 'We do not collect and use any of personal information, except those used in Google analytics. This third party service provider has their own privacy policies on using such information which can be found <0>here</0>.',
                            },
                            {
                                header: 'Log Data and Cookies',
                                text: 'Though we don\'t require the information from your AppleIds and cookies it may be used (in the Free version) for advertisement targeting. In the paid version which contains no advertisements this won\'t be used.',
                            },
                            {
                                header: 'Disclosure to Third Parties',
                                text: 'Having provided you with the apps we do not take any responsibility in the content you will upload into it.',
                            },
                            {
                                header: 'Changes To This Privacy Policy',
                                text: 'In future we may update our Privacy Policy. In this case we will notify you of changes by posting them on this page. We advise you to review this Privacy Policy page from time to time. Changes to this Privacy Policy are effective from the moment they are posted here.',
                            },
                        ],
                    },
                    aboutUs: {
                        title: 'About Hedgehog <br/>Reader Team',
                        we: 'Here we are:',
                        thanks: '(Many thanks to <0>Anna Laurson</0> for this cutest image of us)',
                        comment: 'Please keep in mind that we will consider emails related to the work of the app only.',
                        images: [
                            {type: aboutLinksTypes.feedback, text: 'Leave us feedback', alt: 'Feedback icon'},
                            {type: aboutLinksTypes.email, text: 'Write to us', alt: 'Email icon'},
                            {type: aboutLinksTypes.facebook, text: 'Our Facebook page', alt: 'Facebook icon'},
                            {type: aboutLinksTypes.instagram, text: 'Our Instagram', alt: 'Instagram icon'},
                        ],
                    },
                    genres: {
                        title: 'View and/or add FB2 Genres',
                        code: 'Code',
                        name: 'Name',
                        alias: 'Alias',
                        add: 'Add genre',
                        withParent: 'With parent genre?',
                        viewTitle: 'You\'ve sent us the following genre:',
                        viewParent: 'Sub-genre of ',
                        viewSwitchButton: 'Send another one',
                        legend: ['Genres from the FB2 standard are displayed in this color;', 'Additional genres the app does recognise;', 'New genres requested by users; not available in version <bold><data></data></bold>, will appear in the next one.'],
                        connectingError: 'Core service is experiencing troubles. Please, repeat attempt a little bit later.',
                        info: 'Genre code should contain only letters or underscores sign and be nor shorter than 3 symbols.<br/>It can be found in <0></0><2>genre</2><1></1> tag of <0></0><2>title-info</2><1></1> of an FB2 book\'s <0></0><2>description</2><1></1>.',
                        total: 'Total',
                        underApprove: 'Under approve',
                        down: 'Scroll down to the form',
                        texts: ['The FB2 standard defines an extensive set of genres to which this or that book can be classified. Our application is quite good at working with searching/filtering your personal library based on the information provided. Therefore, you should not ignore this possibility; if not all of your books have a genre assigned, then it makes sense to do this, especially since this can be done in almost two or three taps.',
                            'But life does not stand still. Unfortunately, the FB2 standard is not developing (although it is already the best choice for formatting books), readers and writers are proposing new genres, and there are existing works that do not exactly match any of the proposed one. So, what should we do?',
                            'One option is to select the closest genre from the existing ones (the list of genres defined in FictionBook standard v.2.1 can be found <0>here</0>), but you can as well add a genre to the application by yourself.',
                            'When adding, the following factors should be considered:',
                            ['the new genre will be recognised by our app only (although, of course, there is a possibility for it to be included into the standard, but the chances are little);',
                                'the code of a genre can only contain ASCII table 7-bit characters (English letters without spaces or punctuation marks);',
                                'though the genre can be anything, but it is better to adhere to the form used in the FB2 standard;',
                                'the name of the genre will be translated into all languages supported by the application, so please try to avoid abstruse wording and “wordplay”;',
                                'By sending us a genre through the form above, you are actually modifying the application code, and if your request does not conflict with the rest of the data, you can be sure that your change will be included in the next build.']],
                        aliases: 'This genre has the following aliases:',
                        oneAlias: 'This genre has an alias:',
                        genreExists: 'Genre with the code \'<0></0>\' already exists',
                        addNewAlias: 'Click this button to add an alias to genre',
                    },
                    feedback: {
                        title: 'Leave feedback',
                        fromTitle: 'Your name (<0>optional</0>):',
                        fromAddress: 'Your email (<0>optional</0>):',
                        addressReminder: 'Please, keep in mind that we can answer you only when email has been provided',
                        addressError: 'Wrong email format',
                        text: 'Text (<0>required</0>):',
                        button: 'Send!',
                        clear: 'Clear',
                        cancel: 'Cancel',
                        viewTitle: 'You\'ve sent the following message:',
                        viewFrom: 'From:',
                        viewText: 'Text:',
                        viewSwitchButton: 'Send another one',
                        sendingError: 'Core service is offline. Your message has not been sent. Please, repeat attempt a little bit later.',
                        connectingError: 'Core service is offline. Messages can\'t be sent. Please, visit the page a little bit later.',
                        tooManyMessages: 'We appreciate your desire to give us feedback, but there has been too many message from your IP recently. Please, come a little bit later',
                    },
                    migrationGuide: {
                        title: 'Migration from 2.X to 3.X version Guide',
                        descr: 'Steps to implement',
                        steps: [
                            {pre: 'System settings', text: 'Make sure iCloud Drive is on'},
                            {pre: 'In 2.X', text: 'Turn iCloud Backup on'},
                            {
                                pre: 'In 2.X',
                                text: 'make an operation with the library (for example, moving a book to another shelf)'
                            },
                            {pre: 'In 3.X', text: 'Turn iCloud Backup on'},
                            {pre: 'In 3.X', text: 'Press ‘Restore from backup'},
                            {
                                pre: 'In 3.X',
                                text: 'You’ll see available backups screen, where you should choose the freshly created one'
                            },
                            {pre: 'In 3.X', text: 'Confirm restoring from backup'},
                        ]
                    },
                    imagesAltTexts: {
                        badge: 'App Store badge',
                        qrCode: 'QR Code leading to Hedgehog Reader\'s page in Appstore',
                        hedgehogs: 'A drawing depicting two hedgehogs',
                    },
                    links: {
                        topLink: 'to the top',
                        goBack: 'Go back',
                    },
                    notification: {
                        title: 'Dear users! If you\'ve stumbled upon a book which makes the app to crash or is being read aloud incorrectly...',
                        text: '... please do send us the book via <0>email</0> (with the subject like \'A crash on import\' - optionally).',
                        comment: 'In order to avoid copyright infringement, we undertake to delete books received in this way as soon as we fix the problem caused by them.'
                    },
                    hedgecheck: {
                        title: 'HedgeCheck',
                        familyTitle: '... and let me introduce the youngest "brother" of mine - ',
                        slogan: 'An utility for handling checklists, based on a tree structure, allows to create multiple lists, to export/import them etc.',
                        features: [
                            'has tree structure with arbitrary amount of level',
                            'import from text and csv files',
                            'export/import to internal format "hedgecheck".',
                        ],
                        screenshots:
                            [
                                {
                                    text: 'Main screen',
                                    type: productsData.get(products.hedgecheck).screenshotsTypes.mainScreen
                                },
                                {
                                    text: 'Main screen - edit lists',
                                    type: productsData.get(products.hedgecheck).screenshotsTypes.mainEdit
                                },
                                {
                                    text: 'Checklist',
                                    type: productsData.get(products.hedgecheck).screenshotsTypes.checklist
                                },
                                {
                                    text: 'New checklist',
                                    type: productsData.get(products.hedgecheck).screenshotsTypes.newChecklist
                                },
                                {
                                    text: 'Editing checklist',
                                    type: productsData.get(products.hedgecheck).screenshotsTypes.checklistEdit
                                },
                                {
                                    text: 'Exporting checklist',
                                    type: productsData.get(products.hedgecheck).screenshotsTypes.export
                                },
                            ],
                        privacyPolicy: {title: 'HedgeCheck\'s <br/>Privacy Policy'}
                    }
                },
            },
            es: {
                translation: {
                    index: {
                        title: '"ErizoLector" - lector de libros electrónicos',
                        familyTitle: '... y este es mi hermano mayor - ',
                        header: {
                            title: 'ErizoLector',
                            slogan: 'Un lector de libros para aquellos que valoran su tiempo',
                        },
                        menu:
                            [
                                {title: 'Descargar', type: menuItemTypes.download},
                                {title: 'Características', type: menuItemTypes.features},
                                {title: 'Capturas de pantalla', type: menuItemTypes.screenshots},
                                {title: 'Vídeos', type: menuItemTypes.videos},
                                {title: 'Sobre nosotros', type: menuItemTypes.about, newPage: true},
                                {title: 'Política de privacidad', type: menuItemTypes.privacy, newPage: true},
                                {title: 'Géneros de FB2', type: menuItemTypes.genres, newPage: true},
                                {title: 'Guía de migración', type: menuItemTypes.migrationGuide, newPage: true}
                            ],
                        download: {
                            install: 'Para instalar la aplicación, lea el código QR con una cámara estándar de su iPhone o iPad...',
                            or: '...O...',
                            visit: '...visite',
                            page: 'nuestra página en Appstore'
                        },
                        features: [
                            'admite formatos: FB2, EPUB, MOBI, RTF, DOCX, HTML, MHT/MHTML, archivo WEB (de Apple), CHM, TXT, CBZ (cómic ZIP) y PDF;<0>si el archivo PDF tiene una capa de texto, el texto se interpretará y el libro se formateará como uno de texto. De lo contrario, se abrirá como un conjunto de imágenes normales, como se hace con los cómics;</0>',
                            'reproduce audiolibros de formatos MP3, M4A y M4B;',
                            'la importación desde dispositivos locales, iCloud, Dropbox, servidores conectados por protocolo SMB, como libro único y como lote;',
                            'la capacidad de crear una estructura de estanterías internas, con búsqueda/filtrado por géneros y palabras clave;',
                            'posee búsqueda de texto completo de todos los contenidos de la biblioteca, junto con las notas creadas;',
                            'la capacidad de traducir palabras y frases seleccionadas al idioma elegido;',
                            'leer el texto en voz alta con diferentes variantes de velocidad y operar desde el control de la media;',
                            'la capacidad para corregir errores tipográficos rápidamente;',
                            'marcadores y notas, posibilidad de exportarlos como un archivo;',
                            'la capacidad de descargar libros directamente desde servidores compatibles con OPDS.'
                        ],
                        screenshots:
                            [
                                {
                                    text: 'Importar pantalla',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.importer
                                },
                                {
                                    text: 'Vista de estantería (\'Modo mosaicos\')',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.shelf
                                },
                                {
                                    text: 'Página que contiene imagen',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.withImage
                                },
                                {
                                    text: 'Imagen expandida',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.withImageExpanded
                                },
                                {
                                    text: 'Página que contiene la tabla',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.table
                                },
                                {
                                    text: 'Tabla expandida',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.tableExpanded
                                },
                                {
                                    text: 'Menú contextual en el texto seleccionado',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.translate
                                },
                                {
                                    text: 'Ventana emergente de traducción',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.translated
                                },
                                {
                                    text: 'Menú contextual sobre el texto con una nota creada',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.noteAndMenu
                                },
                                {
                                    text: 'Contenido',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.contents
                                },
                                {
                                    text: 'Notas con botones de menú',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.notes
                                },
                                {
                                    text: 'Selección de voz',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.voiceSelection
                                },
                                {
                                    text: 'En el proceso de lectura en voz alta',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.readAloud
                                },
                                {
                                    text: 'Control de media al leer en voz alta',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.mediaControl
                                },
                                {
                                    text: 'Audiolibro',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.audioBook
                                },
                                {
                                    text: 'Escuchar un audiolibro',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.audioBookReading
                                },
                                {
                                    text: 'Configuración de audiolibros',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.audioBookSettings
                                },
                                {
                                    text: 'Control de medios al escuchar audiolibros',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.audioBookMediaControl
                                },
                            ],
                        videoSubtitle: '(en inglés)',
                    },
                    privacyPolicy: {
                        title: 'Política <br/>de privacidad <br/>del ErizoLector',
                        paragraphs: [
                            {
                                header: 'Recopilación y uso de información personal',
                                text: 'No recopilamos ni utilizamos ninguna información personal, excepto la utilizada en Google Analytics. Este proveedor de servicios externo tiene sus propias políticas de privacidad sobre el uso de dicha información, que se pueden encontrar aquí <0>aquí</0>.',
                            },
                            {
                                header: 'Datos de registro y cookies',
                                text: 'Aunque no requerimos la información de sus AppleIds y cookies, puede usarse (en la versión gratuita) para la orientación de anuncios. En la versión de pago que no contiene anuncios, esto no se utilizará.',
                            },
                            {
                                header: 'Divulgación a terceros',
                                text: 'Después de haberle proporcionado las aplicaciones, no asumimos ninguna responsabilidad en el contenido que cargará en ellas.',
                            },
                            {
                                header: 'Cambios a esta política de privacidad',
                                text: 'Es posible que en el futuro actualicemos nuestra Política de privacidad. En este caso, le notificaremos los cambios publicándolos en esta página. Le recomendamos que revise esta página de Política de privacidad de vez en cuando. Los cambios a esta Política de privacidad entran en vigencia desde el momento en que se publican aquí.',
                            },
                        ],
                    },
                    aboutUs: {
                        title: 'Acerca del equipo <br/>del ErizoLector',
                        we: 'Aquí estamos:',
                        thanks: '(Muchas gracias a <0>Anna Laurson</0> por esta imagen tan tierna de nosotros.)',
                        comment: 'Tenga en cuenta que solo consideraremos los correos electrónicos relacionados con el trabajo de la aplicación.',
                        images: [
                            {type: aboutLinksTypes.feedback, text: 'Déjanos sus opinión', alt: 'Icono de comentarios'},
                            {type: aboutLinksTypes.email, text: 'Escríbenos', alt: 'Icono de correo electrónico'},
                            {
                                type: aboutLinksTypes.facebook,
                                text: 'Nuestra página de Facebook',
                                alt: 'Icono de facebook'
                            },
                            {type: aboutLinksTypes.instagram, text: 'Nuestro instagram', alt: 'Icono de Instagram'},
                        ],
                    },
                    genres: {
                        title: 'Ver y/o agregar géneros FB2',
                        code: 'Código',
                        name: 'Nombre',
                        alias: 'Alias',
                        add: 'Agregar genero',
                        withParent: '¿Con género padre?',
                        viewTitle: 'Nos has usted enviado el siguiente género:',
                        viewParent: 'Subgénero de ',
                        viewSwitchButton: 'Envia otro',
                        legend: ['Los géneros del estándar FB2 se muestran en este color;', 'Los géneros adicionales que la aplicación sí reconoce;', 'Nuevos géneros solicitados por los usuarios; no disponible en la versión <bold><data></data></bold>, aparecerán en la próxima.'],
                        connectingError: 'El servicio principal está experimentando problemas. Por favor, visite la página un poco más tarde.',
                        info: 'El código de género debe contener solo letras o guiones bajos y no tener menos de 3 símbolos.<br/> Se puede encontrar en la etiqueta <0></0><2>genre</2><1></1> de <0></0><2>title-info</2><1></1> de la <0></0><2>description</2><1></1> de un libro de FB2.',
                        total: 'Total',
                        underApprove: 'Esperando aprobar',
                        down: 'Desplácese hacia abajo hasta el formulario',
                        texts: ['El estándar FB2 define un extenso conjunto de géneros en los que se puede clasificar tal o cual libro. Nuestra aplicación es bastante buena para buscar/filtrar su biblioteca personal en función de la información proporcionada. Por lo tanto, no debes ignorar esta posibilidad; Si no todos tus libros tienen un género asignado, entonces tiene sentido hacerlo, especialmente porque se puede hacer con casi dos o tres toques.',
                            'Pero la vida no se detiene. Desafortunadamente, el estándar FB2 no se está desarrollando (aunque ya es la mejor opción para formatear libros), lectores y escritores proponen nuevos géneros y hay obras existentes que no coinciden exactamente con ninguna de las propuestas. ¿Entonces, qué debemos hacer?',
                            'Una opción es seleccionar el género más cercano de los existentes (la lista - en inglés - de géneros definidos en el estándar FictionBook v.2.1 se puede encontrar <0>aquí</0>), pero también puedes agregar un género a la aplicación tú mismo.',
                            'Al agregar, se deben tener en cuenta los siguientes factores:',
                            ['el nuevo género sólo será reconocido por nuestra aplicación (aunque, por supuesto, existe la posibilidad de que se incluya en el estándar, pero las posibilidades son pocas);',
                                'el código de un género sólo puede contener caracteres de tabla ASCII de 7 bits (letras inglesas sin espacios ni signos de puntuación);',
                                'aunque el género puede ser cualquier cosa, es mejor atenerse a la forma utilizada en el estándar FB2;',
                                'el nombre del género se traducirá a todos los idiomas admitidos por la aplicación, así que trate de evitar palabras abstrusas y juegos de palabras;',
                                'al enviarnos un género a través del formulario anterior, en realidad está modificando el código de la aplicación y, si su solicitud no entra en conflicto con el resto de los datos, puede estar seguro de que su cambio se incluirá en la próxima compilación.']],
                        aliases: 'Este género tiene los siguientes alias:',
                        oneAlias: 'Este género tiene un alias:',
                        genreExists: 'El género con el código \'<0></0>\' ya existe',
                        addNewAlias: 'Haga clic en este botón para agregar un alias al género',
                    },
                    feedback: {
                        title: 'Deja un comentario',
                        fromTitle: 'Tu nombre (<0>opcional</0>):',
                        fromAddress: 'Tu email (<0>opcional</0>):',
                        addressReminder: 'Por favor, tenga en cuenta que solo podemos responderle cuando se haya proporcionado un correo electrónico.',
                        addressError: 'Formato de correo electrónico incorrecto',
                        text: 'Texto (<0>requerido</0>):',
                        button: '¡Enviar!',
                        clear: 'Borrar',
                        cancel: 'Cancelar',
                        viewTitle: 'Has enviado el siguiente mensaje:',
                        viewFrom: 'De:',
                        viewText: 'Texto:',
                        viewSwitchButton: 'Envia otro',
                        sendingError: 'El servicio principal está fuera de línea. Su mensaje no ha sido enviado. Por favor, repita el intento un poco más tarde.',
                        connectingError: 'El servicio principal está fuera de línea. No se pueden enviar mensajes. Por favor, visite la página un poco más tarde.',
                        tooManyMessages: 'Agradecemos su deseo de darnos su opinión, pero ha habido demasiados mensajes de su IP recientemente. Por favor, ven un poco más tarde.',
                    },
                    migrationGuide: {
                        title: 'Guía de migración de la versión 2.X a 3.X',
                        descr: 'Pasos para implementar',
                        steps: [
                            {pre: 'Ajustes del sistema', text: 'Asegúrate de que iCloud Drive esté activado'},
                            {pre: 'En 2.X', text: 'Activar la copia de seguridad de iCloud'},
                            {
                                pre: 'En 2.X',
                                text: 'realizar una operación con la biblioteca (por ejemplo, mover un libro a otro estante)'
                            },
                            {pre: 'En 3.X', text: 'Activar la copia de seguridad de iCloud'},
                            {pre: 'En 3.X', text: 'Presione \'Restaurar desde copia de seguridad\''},
                            {
                                pre: 'En 3.X',
                                text: 'Verás la pantalla de copias de seguridad disponibles, donde deberás elegir la recién creada.'
                            },
                            {pre: 'En 3.X', text: 'Confirmar la restauración desde la copia de seguridad'},
                        ]
                    },
                    imagesAltTexts: {
                        badge: 'Insignia de la tienda de aplicaciones',
                        qrCode: 'Código QR que conduce a la página del ErizoLector en App Store',
                        hedgehogs: 'Un dibujo que representa a dos erizos.',
                    },
                    links: {
                        topLink: 'a la parte superior',
                        goBack: 'Volver',
                    },
                    notification: {
                        title: 'Estimados usuarios! Si se ha topado con un libro que hace que la aplicación falle o se lee en voz alta incorrectamente...',
                        text: '... por favor envíenos el libro por <0>correo electrónico</0> (con el asunto como \'Un fallo al importar\', opcionalmente).',
                        comment: 'Para evitar infracciones de derechos de autor, nos comprometemos a eliminar los libros recibidos de esta manera tan pronto como solucionemos el problema causado por ellos.'
                    },
                    hedgecheck: {
                        title: 'vErizicación',
                        familyTitle: '... y déjame presentarte al "hermano" más joven mío - ',
                        slogan: 'Una utilidad para manejar listas de verificación, basada en una estructura de árbol, permite crear múltiples listas, exportarlas/importarlas, etc.',
                        features: [
                            'tiene una estructura de árbol con una cantidad arbitraria de niveles',
                            'importar desde archivos de texto y csv',
                            'exportar/importar a formato interno "hedgecheck".',
                        ],
                        screenshots:
                            [
                                {
                                    text: 'Pantalla principal',
                                    type: productsData.get(products.hedgecheck).screenshotsTypes.mainScreen
                                },
                                {
                                    text: 'Pantalla principal - editar listas',
                                    type: productsData.get(products.hedgecheck).screenshotsTypes.mainEdit
                                },
                                {
                                    text: 'Una lista de verificación',
                                    type: productsData.get(products.hedgecheck).screenshotsTypes.checklist
                                },
                                {
                                    text: 'Al crear la lista de verificación',
                                    type: productsData.get(products.hedgecheck).screenshotsTypes.newChecklist
                                },
                                {
                                    text: 'Al editar la lista de verificación',
                                    type: productsData.get(products.hedgecheck).screenshotsTypes.checklistEdit
                                },
                                {
                                    text: 'Al exportar lista de verificación',
                                    type: productsData.get(products.hedgecheck).screenshotsTypes.export
                                },
                            ],
                        privacyPolicy: {title: 'Política <br/>de privacidad <br/>de vErizicación'}
                    }
                },
            },
            pl: {
                translation: {
                    index: {
                        title: '"Jeż-czytelnik" - czytnik e-Booków',
                        familyTitle: '... a to mój starszy brat - ',
                        header: {
                            title: 'Jeż-czytelnik',
                            slogan: 'Czytnik e-Booków dla tych, którzy cenią czas',
                        },
                        menu:
                            [
                                {title: 'Pobierz', type: menuItemTypes.download},
                                {title: 'Cechy', type: menuItemTypes.features},
                                {title: 'Pogląd', type: menuItemTypes.screenshots},
                                {title: 'Filmy', type: menuItemTypes.videos},
                                {title: 'O nas', type: menuItemTypes.about, newPage: true},
                                {title: 'Polityka prywatności', type: menuItemTypes.privacy, newPage: true},
                                {title: 'Kategorii FB2', type: menuItemTypes.genres, newPage: true},
                                {title: 'Przewodnik po migracji', type: menuItemTypes.migrationGuide, newPage: true}
                            ],
                        download: {
                            install: 'Aby zainstalować aplikację, przeczytaj kod QR kamerą swojego iPhone\'a lub iPada...',
                            or: '...LUB...',
                            visit: '...odwiedź',
                            page: 'naszą stronę w Appstore'
                        },
                        features: [
                            'obsługuje formaty FB2, EPUB, MOBI, RTF, DOCX, HTML, MHT/MHTML, archiwum WWW (od Apple\'a), CHM, TXT, CBZ (komiks ZIP) i PDF;<0>jeśli plik PDF zawiera warstwę tekstową, tekst zostanie zinterpretowany, a książka zostanie sformatowana jako tekstowa. Jeśli nie, zostanie otwartа jako zestaw obrazków, tak jak ma to miejsce w przypadku komiksów;</0>',
                            'odtwarza audiobooki w formatach MP3, M4A i M4B;',
                            'importuje książek z urządzeń lokalnych, iCloud, Dropbox, serwerów połączonych protokołem SMB, pojedyncze i kilka na raz;',
                            'ma możliwość tworzenia wewnętrznej struktury półek z wyszukiwaniem / filtrowaniem według kategorii i słów kluczowych;',
                            'posiada funkcję pełnotekstowego wyszukiwania całej zawartości biblioteki wraz z utworzonymi notatkami;',
                            'ma też możliwość tłumaczenia słów i fraz na wybrany język, z ustawieniem listy języków do tłumaczenia;',
                            'czyta teksty na głos z różnymi wariantami prędkości i sterowaniem z media control;',
                            'ma możliwość szybkiego poprawiania literówek;',
                            'można dodawać zakładki i notatki z możliwością wyeksportowania jako pliku;',
                            'oraz posiada możliwość bezpośredniego pobierania książek z serwerów obsługujących OPDS.'
                        ],
                        screenshots:
                            [
                                {
                                    text: 'Ekran importu',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.importer
                                },
                                {
                                    text: 'Widok półki (\'Tryb kafelków\')',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.shelf
                                },
                                {
                                    text: 'Strona zawierająca obraz',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.withImage
                                },
                                {
                                    text: 'Obraz rozwinięty',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.withImageExpanded
                                },
                                {
                                    text: 'Strona zawierająca tabelę',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.table
                                },
                                {
                                    text: 'Tabela rozwinięta',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.tableExpanded
                                },
                                {
                                    text: 'Menu kontekstowe na zaznaczonym tekście',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.translate
                                },
                                {
                                    text: 'Wyskakujące okienko tłumaczenia',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.translated
                                },
                                {
                                    text: 'Menu kontekstowe na tekście z utworzoną notatką',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.noteAndMenu
                                },
                                {
                                    text: 'Spis treści',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.contents
                                },
                                {
                                    text: 'Notatki z przyciskami menu',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.notes
                                },
                                {
                                    text: 'Wybór głosu',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.voiceSelection
                                },
                                {
                                    text: 'W trakcie czytania na głos',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.readAloud
                                },
                                {
                                    text: 'Media control w trakcie czytania na głos',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.mediaControl
                                },
                                {
                                    text: 'Audiobook',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.audioBook
                                },
                                {
                                    text: 'Słuchanie audiobooka',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.audioBookReading
                                },
                                {
                                    text: 'Ustawienia audiobooka',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.audioBookSettings
                                },
                                {
                                    text: 'Media control podczas słuchania audiobooka',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.audioBookMediaControl
                                },
                            ],
                        videoSubtitle: '(w języku angielskim)',
                    },
                    privacyPolicy: {
                        title: 'Polityka <br/>prywatności <br/>aplikacji <br/>"Jeż-czytelnik"',
                        paragraphs: [
                            {
                                header: 'Zbieranie i wykorzystywanie danych osobowych',
                                text: 'Nie zbieramy i nie wykorzystujemy żadnych danych osobowych, z wyjątkiem potrzebnych do Google Analytics. Zasady polityki prywatności tego dostawcy usług można znaleźć <0>tutaj</0>.',
                            },
                            {
                                header: 'Log Data i "Ciasteczka"',
                                text: 'Nie potrzebujemy żadnej informacji z Apple Id i "Ciasteczek" użytkowników, ale mogą one zostać wykorzystane (wyłącznie w wersji darmowej) do kierowania reklam.',
                            },
                            {
                                header: 'Ujawnianie informacji stronom trzecim',
                                text: 'Dostarczając aplikację użytkownikom, nie bierzemy żadnej odpowiedzialności za treści, które mogą oni do nią załadować.',
                            },
                            {
                                header: 'Zmiany w polityce prywatności',
                                text: 'W przyszłości niniejsza polityka prywatności może mieć zmiany. Wszystkie powiadomienia o takich przypadkach będą publikowane na tej stronie. Zalecamy od czasu do czasu przejrzywać stronę. Zmiany w polityce prywatności obowiązują od momentu ich opublikowania tu.',
                            },
                        ],
                    },
                    aboutUs: {
                        title: 'O zespole tworców <br/>"Jeża-czytelnika"',
                        we: 'Oto jesteśmy:',
                        thanks: '(Dziękujęmy serdecznie <0>Annie Laurson</0> za ten nasz portret)',
                        comment: 'Prosimy pamiętać, że będziemy rozważać tylko e-maile związane z działaniem aplikacji.',
                        images: [
                            {type: aboutLinksTypes.feedback, text: 'Zostaw opinię', alt: 'Ikona komentarza'},
                            {type: aboutLinksTypes.email, text: 'Napisz do nas', alt: 'Ikona e-mail'},
                            {type: aboutLinksTypes.facebook, text: 'Nasza strona na Facebooku', alt: 'Ikona Facebooka'},
                            {type: aboutLinksTypes.instagram, text: 'Nasz Instagram', alt: 'Ikona Instagrama'},
                        ],
                    },
                    genres: {
                        title: 'Zobaczyć oraz/lub dodać kategorii FB2',
                        code: 'Kod',
                        name: 'Nazwa',
                        alias: 'Alias',
                        add: 'Dodać kategorię',
                        withParent: 'Z kategorią nadrzędną?',
                        viewTitle: 'Wysłałe(a)ś nam następującą kategorię:',
                        viewParent: 'Podkategoria od ',
                        viewSwitchButton: 'Wyślij inną',
                        legend: ['Kategorii ze standardu FB2 są wyświetlane w tym kolorze;', 'Dodatkowe kategorii rozpoznawane przez aplikację;', 'Nowe kategorii żądane przez użytkowników; nie dostępne w wersji <bold><data></data></bold>, pojawią się w następnej.'],
                        connectingError: 'Usługa zaplecza ma problemy. Zapraszamy do odwiedzenia strony nieco później.',
                        info: 'Kod kategorii powinien zawierać wyłącznie litery bądź znak podkreślenia i być nie krótszy niż 3 znaki.<br/>Można go znaleźć w tagu <0></0><2>genre</2><1></1> taga <0></0><2>title-info</2><1></1> w sekcji <0></0><2>description</2><1></1> pliku FB2.',
                        total: 'Łącznie',
                        underApprove: 'Czekające na akceptację',
                        down: 'W dół do formularza',
                        texts: ['Standard FB2 definiuje obszerny zestaw kategorii, do których można zaklasyfikować tę lub inną książkę. Nasza aplikacja całkiem dobrze radzi sobie z wyszukiwaniem/filtrowaniem osobistej biblioteki czytelników na podstawie dostarczonych informacji. Dlatego nie należy ignorować tej możliwości; jeśli nie wszystkie Twoje książki mają przypisana kategorię, warto to zrobić, zwłaszcza że to się robi za pomocą kilka kliknięć.',
                            'Ale życie nie stoi w miejscu. Niestety standard FB2 się nie rozwija (choć nadal pozostaje najlepszym do formatowania książek), czytelnicy i pisarze proponują nowe kategorii, istnieją również wydane już dzieła, które nie do końca pasują do żadnej z proponowanych kategorii. Więc co powinniśmy zrobić?',
                            'Jedną z opcji jest wybranie najbliżej pasującej kategorii spośród istniejących (lista - w języku angielskim - według standardu FictionBook v.2.1 jest <0>tutaj</0>), ale równie dobrze można samodzielnie dodać do aplikacji nową.',
                            'Podczas dodawania należy wziąć pod uwagę następujące czynniki:',
                            ['nowa kategoria będzie rozpoznawana tylko przez naszą aplikację (choć oczywiście istnieje możliwość włączenia jej do standardu, ale szanse są niewielkie);',
                                'kod kategorii może zawierać wyłącznie 7-bitowe znaki z tablicy ASCII (angielskie litery bez spacji i znaków interpunkcyjnych);',
                                'kod może mieć dowolną formę, ale lepiej trzymać się tej stosowanej w standardzie FB2;',
                                'nazwa kategorii zostanie przetłumaczona na wszystkie języki obsługiwane przez aplikację, dlatego prosimy o unikanie zawiłych sformułowań i gry słów;',
                                'Przesyłając nam kategorię poprzez powyższy formularz, tak naprawdę modyfikujesz kod aplikacji i jeśli Twoje żądanie nie koliduje z resztą danych, możesz mieć pewność, że Twoja zmiana zostanie uwzględniona w kolejnej kompilacji.']],
                        aliases: 'Kategoria ma aliasy:',
                        oneAlias: 'Kategoria ma alias:',
                        genreExists: 'Kategoria o kodzie \'<0></0>\' już istnieje',
                        addNewAlias: 'Kliknij ten przycisk, aby dodać alias do kategorii',
                    },
                    feedback: {
                        title: 'Prześlij opinię',
                        fromTitle: 'Twoje imię (<0>opcjonalnie</0>):',
                        fromAddress: 'Twój email (<0>opcjonalnie</0>):',
                        addressReminder: 'Pamiętaj, że możemy wysłać odpowiedź tylko w przypadku, gdy Twój adres e-mail został podany',
                        addressError: 'Zły format email',
                        text: 'Tekst (<0>wymagane</0>):',
                        button: 'Wysłać!',
                        clear: 'Oczyścić',
                        cancel: 'Anulować',
                        viewTitle: 'Wysłałe(a)ś następującą wiadomość:',
                        viewFrom: 'Od:',
                        viewText: 'Tekst:',
                        viewSwitchButton: 'Wyślij inną',
                        sendingError: 'Usługa zaplecza jest w trybie offline. Twoja wiadomość nie została wysłana. Proszę powtórzyć próbę nieco później.',
                        connectingError: 'Usługa zaplecza jest w trybie offline. Nie można wysyłać wiadomości. Zapraszamy do odwiedzenia strony nieco później.',
                        tooManyMessages: 'Dziękujemy za chęć przekazania nam opinii, ale ostatnio otrzymaliśmy zbyt wiele wiadomości z Twojego adresu IP. Proszę, przyjdź trochę później',
                    },
                    migrationGuide: {
                        title: 'Przewodnik migracji z wersji 2.X do 3.X',
                        descr: 'Kroki do wdrożenia',
                        steps: [
                            {pre: 'Ustawienia systemowe', text: 'Upewnij się, że iCloud Drive jest włączony'},
                            {pre: 'W 2.X', text: 'Włącz kopię zapasową iCloud'},
                            {
                                pre: 'W 2.X',
                                text: 'wykonaj operację na bibliotece (np. przenieść książkę na inną półkę)'
                            },
                            {pre: 'W 3.X', text: 'Włącz kopię zapasową iCloud'},
                            {pre: 'W 3.X', text: 'Naciśnij „Przywróć z kopii zapasowej”'},
                            {
                                pre: 'W 3.X',
                                text: 'Zobaczysz ekran dostępnych kopii zapasowych, z którego musisz wybrać nowo utworzoną'
                            },
                            {pre: 'W 3.X', text: 'Potwierdź przywracanie z kopii zapasowej'},
                        ]
                    },
                    imagesAltTexts: {
                        badge: 'Odznaka App Store',
                        qrCode: 'Kod QR prowadzący do strony Jeża-czytelnika w Appstorze',
                        hedgehogs: 'Rysunek przedstawiający dwa jeże',
                    },
                    links: {
                        topLink: 'do góry',
                        goBack: 'Wstęcz',
                    },
                    notification: {
                        title: 'Drodzy użytkownicy! Jeżeli Państwo się natknęli na książkę, która powoduje awarię aplikacji lub jest nieprawidłowo czytana na głos...',
                        text: '... prosimy wysłać ją nam <0>drogą mailową</0> (z tematem \'Awaria podczas importu\' – opcjonalnie).',
                        comment: 'Aby uniknąć naruszenia praw autorskich, zobowiązujemy się do usunięcia otrzymanych w ten sposób książek niezwłocznie po naprawieniu spowodowanego nimi problemu.'
                    },
                    hedgecheck: {
                        title: 'JeżyCheck',
                        familyTitle: '... i pozwólcie Państwo, że przedstawię mojego młodszego „brata”, jest to ',
                        slogan: 'Appka do obsługi list kontrolnych (checklistów), oparta na strukturze drzewiastej, pozwala na tworzenie wielu list, eksportu/importu itp.',
                        features: [
                            'ma strukturę drzewiastą z dowolną ilością poziomów',
                            'import z plików tekstowych i CSV',
                            'eksport/import do wewnętrznego formatu „hedgecheck”.',
                        ],
                        screenshots:
                            [
                                {
                                    text: 'Główny ekran',
                                    type: productsData.get(products.hedgecheck).screenshotsTypes.mainScreen
                                },
                                {
                                    text: 'Główny ekran - edytowanie listów',
                                    type: productsData.get(products.hedgecheck).screenshotsTypes.mainEdit
                                },
                                {
                                    text: 'Przykład listy kontrolnej',
                                    type: productsData.get(products.hedgecheck).screenshotsTypes.checklist
                                },
                                {
                                    text: 'Tworzenie nowej listy kontrolnej',
                                    type: productsData.get(products.hedgecheck).screenshotsTypes.newChecklist
                                },
                                {
                                    text: 'Edycja listy kontrolnej',
                                    type: productsData.get(products.hedgecheck).screenshotsTypes.checklistEdit
                                },
                                {
                                    text: 'Eksportowanie listy kontrolnej',
                                    type: productsData.get(products.hedgecheck).screenshotsTypes.export
                                },
                            ],
                        privacyPolicy: {title: 'Polityka <br/>prywatności <br/>aplikacji <br/>"JeżyCheck"'}
                    }
                },
            },
            ua: {
                translation: {
                    index: {
                        title: '"Їжачок-читач" - читанка електронних книг',
                        familyTitle: '... а це мій старший брат - ',
                        header: {
                            title: 'Їжачок-читач',
                            slogan: 'Програма для читання книг для тих, хто цінує свій час',
                        },
                        menu:
                            [
                                {title: 'Завантажити', type: menuItemTypes.download},
                                {title: 'Функції', type: menuItemTypes.features},
                                {title: 'Скріншоти', type: menuItemTypes.screenshots},
                                {title: 'Відео', type: menuItemTypes.videos},
                                {title: 'Про нас', type: menuItemTypes.about, newPage: true},
                                {title: 'Політика конфіденційності', type: menuItemTypes.privacy, newPage: true},
                                {title: 'Жанри FB2', type: menuItemTypes.genres, newPage: true},
                                {title: 'Посібник з міграції', type: menuItemTypes.migrationGuide, newPage: true}
                            ],
                        download: {
                            install: 'Щоб встановити програму, прочитайте QR-код за допомогою стандартної камери вашого iPhone або iPad...',
                            or: '...АБО...',
                            visit: '...відвідайте',
                            page: 'нашу сторінку в Appstore'
                        },
                        features: [
                            'підтримує формати: FB2, EPUB, MOBI, RTF, DOCX, HTML, MHT/MHTML, Веб-архів (від Apple), CHM, TXT, CBZ (комікс ZIP) та PDF;<0>якщо файл PDF містить текстовий шар, текст буде інтерпретовано, а книга буде відформатована як текст. Якщо ні, вона буде відкрита як набір звичайних картинок, як це робиться для коміксів;</0>',
                            'відтворює аудіокниги форматів MP3, M4A і M4B;',
                            'імпортує з локальних пристроїв, iCloud, Dropbox, серверів, підключених за протоколом SMB, як одну книгу, так і як пакет;',
                            'має можливість створення внутрішньої структури полиць з пошуком/фільтрацією за жанрами та ключовими словами;',
                            'має повнотекстовий пошук усього вмісту бібліотеки разом зi створеними нотатками;',
                            'має можливість перекладати вибрані слова та фрази обраною мовою;',
                            'читає текст вголос з різними варіантами швидкості та керується з media control;',
                            'має можливість швидко виправляти друкарські помилки;',
                            'можна додавати закладки та нотатки з можливістю експорту як файл;',
                            'та має можливість безпосереднього завантаження книг із серверів, які підтримують OPDS.'
                        ],
                        screenshots:
                            [
                                {
                                    text: 'Екран імпорту',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.importer
                                },
                                {
                                    text: 'Перегляд полиці (\'Мозаїчний режим\')',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.shelf
                                },
                                {
                                    text: 'Сторінка, що містить зображення',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.withImage
                                },
                                {
                                    text: 'Розгорнуте зображення',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.withImageExpanded
                                },
                                {
                                    text: 'Сторінка, що містить таблицю',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.table
                                },
                                {
                                    text: 'Розгорнута таблиця',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.tableExpanded
                                },
                                {
                                    text: 'Контекстне меню на виділеному тексті',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.translate
                                },
                                {
                                    text: 'Спливаюче вікно перекладу',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.translated
                                },
                                {
                                    text: 'Контекстне меню на тексті зі приміткою',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.noteAndMenu
                                },
                                {
                                    text: 'Зміст',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.contents
                                },
                                {
                                    text: 'Нотатки з кнопками меню',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.notes
                                },
                                {
                                    text: 'Вибір голосу',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.voiceSelection
                                },
                                {
                                    text: 'Під час читання вголос',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.readAloud
                                },
                                {
                                    text: 'Media control під час читання вголос',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.mediaControl
                                },
                                {
                                    text: 'Аудіокнига',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.audioBook
                                },
                                {
                                    text: 'Прослуховування аудіокниги',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.audioBookReading
                                },
                                {
                                    text: 'Налаштування аудіокниги',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.audioBookSettings
                                },
                                {
                                    text: 'Media control під час прослуховування аудіокниги',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.audioBookMediaControl
                                },
                            ],
                        videoSubtitle: '(англійською мовою)',
                    },
                    privacyPolicy: {
                        title: 'Політика <br/>конфіденційності <br/>програми "Їжачок-<br/>читач"',
                        paragraphs: [
                            {
                                header: 'Збір та використання персональної інформації',
                                text: 'Ми не збираємо та не використовуємо будь-яку особисту інформацію, за винятком тієї, що використовується в аналітиці Google. Цей сторонній постачальник послуг має власну політику конфіденційності щодо використання такої інформації, яку можна знайти <0>тут</0>.',
                                //link: {text: 'тут', url: privacyPolicyExplanationLink},
                            },
                            {
                                header: 'Дані журналу та файли cookie',
                                text: 'Хоча нам не потрібна інформація з ваших AppleIds та файлів cookie, вона може використовуватися (у безкоштовній версії) для націлювання реклами. У платній версії, яка не містить реклами, це не використовуватиметься.',
                            },
                            {
                                header: 'Розкриття інформації третім сторонам',
                                text: 'Надавши вам програми, ми не несемо жодної відповідальності за зміст, який ви до нього завантажуватимете.',
                            },
                            {
                                header: 'Зміни до Політики конфіденційності',
                                text: 'У майбутньому ми можемо оновити нашу Політику конфіденційності. У цьому випадку ми повідомимо вас про зміни, опублікувавши їх на цій сторінці. Ми радимо вам час від часу переглядати цю сторінку Політики конфіденційності. Зміни до цієї Політики конфіденційності набувають чинності з моменту їх розміщення тут.',
                            },
                        ],
                    },
                    aboutUs: {
                        title: 'Про команду <br/>творців <br/>"Їжачка-Читача"',
                        we: 'Ось ми:',
                        thanks: '(Велике спасибі <0>Анні Лаурсон</0> за цей наймиліший наш образ)',
                        comment: 'Пам’ятайте, що ми розглядатимемо лише листи, пов’язані з роботою програми.',
                        images: [
                            {type: aboutLinksTypes.email, text: 'Напишіть нам', alt: 'Піктограма електронної пошти'},
                            {type: aboutLinksTypes.feedback, text: 'Залиште нам відгук', alt: 'Піктограма відгуку'},
                            {type: aboutLinksTypes.facebook, text: 'Наша сторінка у Facebooku', alt: 'значок Facebook'},
                            {type: aboutLinksTypes.instagram, text: 'Наш Instagram', alt: 'Значок Instagram'},
                        ],
                    },
                    genres: {
                        title: 'Перегляньте та/або додайте жанри FB2',
                        code: 'Код',
                        name: 'Назва',
                        alias: 'Псевдонім',
                        add: 'Додати жанр',
                        withParent: 'З батьківським жанром?',
                        viewTitle: 'Ви надіслали нам такий жанр:',
                        viewParent: 'Це піджанр цього ',
                        viewSwitchButton: 'Надіслати інший',
                        legend: ['Жанри зі стандарту FB2 відображаються цим кольором;', 'Додаткові жанри, які програма розпізнає;', 'Нові жанри, запитувані користувачами; недоступні у версії <bold><data></data></bold>, з’являться в наступній.'],
                        connectingError: 'Внутрішній сервер має проблеми. Будь ласка, відвідайте сторінку трохи пізніше.',
                        info: 'Код жанру повинен містити лише латинські літери або знак підкреслення і бути не коротшим 3 символів.<br/>Його можна знайти у тезі <0></0><2>genre</2><1></1> в <0></0><2>title-info</2><1></1> розділу <0></0><2>description</2><1></1> файлу FB2.',
                        total: 'Всього',
                        underApprove: 'Очікують на затвердження',
                        down: 'Прокрутіть вниз до форми',
                        texts: ['Стандарт FB2 визначає широкий набір жанрів, до яких можна віднести ту чи іншу книгу. Наша програма досить добре працює з пошуком/фільтруванням Вашої особистої бібліотеки на основі наданої інформації. Тому не варто ігнорувати цю можливість; якщо не для всіх Ваших книг призначений жанр, то має сенс це зробити, тим більше, що це можна зробити майже двома-трьома дотиками.',
                            'Але життя не стоїть на місці. На жаль, стандарт FB2 не розвивається (хоча він уже є найкращим вибором для форматування книг), читачі та письменники пропонують нові жанри, в додаток є твори, які точно не відповідають жодному із запропонованих. Отже, що робити?',
                            'Одним із варіантів є вибір найближчого жанру з існуючих (перелік - англійською мовою - жанрів, визначених у стандарті FictionBook v.2.1, можна знайти <0>тут</0>), але Ви також можете додати жанр до програми самостійно.',
                            'При додаванні слід враховувати наступні фактори:',
                            ['новий жанр буде розпізнаватися тільки нашим додатком (хоча, звичайно, є можливість включити його в стандарт, але шансів мало);',
                            'код жанру може містити лише 7-бітні символи таблиці ASCII (англійські літери без пробілів і знаків пунктуації);',
                            'хоча код може бути будь-який, краще дотримуватися форми, яка використовується в стандарті FB2;',
                            'назва жанру буде перекладено всіма мовами, які підтримує додаток, тому, будь ласка, намагайтеся уникати незрозумілих формулювань та «ігри слів»;',
                            'надсилаючи нам жанр через форму вище, Ви фактично змінюєте код програми, і якщо Ваш запит не суперечить решті даних, Ви можете бути впевнені, що Ваша зміна буде включена в наступну збірку.']],
                        aliases: 'Цей жанр має такі псевдоніми:',
                        oneAlias: 'Цей жанр має псевдонім:',
                        genreExists: 'Жанр з кодом \'<0></0>\' вже існує',
                        addNewAlias: 'Натисніть цю кнопку, щоб додати псевдонім до жанру',
                    },
                    feedback: {
                        title: 'Надіслати думку',
                        fromTitle: 'Ваше ім\'я (<0>необов\'язково</0>):',
                        fromAddress: 'Ваша електронна адреса (<0>необов\'язково</0>):',
                        addressReminder: 'Пам’ятайте, що ми можемо відповісти Вам, лише якщо Ви вказали адресу електронної пошти',
                        addressError: 'Неправильний формат електронної пошти',
                        text: 'Текст (<0>обов\'язкове</0>):',
                        button: 'Відправити!',
                        clear: 'Очистіть',
                        cancel: 'Скасувати',
                        viewTitle: 'Ви надіслали таке повідомлення:',
                        viewFrom: 'Від:',
                        viewText: 'Текст:',
                        viewSwitchButton: 'Надіслати інше',
                        sendingError: 'Внутрішній сервер офлайн. Ваше повідомлення не надіслано. Будь ласка, повторіть спробу трохи пізніше.',
                        connectingError: 'Внутрішній сервер офлайн. Неможливо надіслати повідомлення. Будь ласка, відвідайте сторінку трохи пізніше.',
                        tooManyMessages: 'Ми цінуємо ваше бажання надіслати нам відгук, але нещодавно з вашої IP-адреси надійшло забагато повідомлень. Приходьте, будь ласка, трохи пізніше',
                    },
                    migrationGuide: {
                        title: 'Посібник з міграції з версії 2.X на 3.X',
                        descr: 'Кроки для реалізації',
                        steps: [
                            {pre: 'Налаштування системи', text: 'Переконайтеся, що iCloud Drive увімкнено'},
                            {pre: 'У 2.X', text: 'Увімкніть iCloud Backup'},
                            {
                                pre: 'У 2.X',
                                text: 'виконати операцію з бібліотекою (наприклад, перемістити книгу на іншу полицю)'
                            },
                            {pre: 'У 3.X', text: 'Увімкніть iCloud Backup'},
                            {pre: 'У 3.X', text: 'Натисніть «Відновити з резервної копії»'},
                            {
                                pre: 'У 3.X',
                                text: 'Ви побачите екран доступних резервних копій, де вам слід вибрати щойно створену'
                            },
                            {pre: 'У 3.X', text: 'Підтвердьте відновлення з резервної копії'},
                        ]
                    },
                    imagesAltTexts: {
                        badge: 'Значок App Store',
                        qrCode: 'QR-код, що веде на сторінку Їжачка-читача в App Store',
                        hedgehogs: 'Малюнок із зображенням двох їжаків',
                    },
                    links: {
                        topLink: 'вгору',
                        goBack: 'Назад',
                    },
                    notification: {
                        title: 'Шановні користувачі! Якщо Ви натрапили на книгу, яка призвела до збою програми або читається вголос неправильно...',
                        text: '... будь ласка, надішліть нам цiю книгу <0>електронною поштою</0> (з темою на кшталт \'Збій під час імпорту\' — за бажанням).',
                        comment: 'Щоб уникнути порушення авторських прав, ми зобов’язуємося видаляти книги, отримані таким чином, щойно виправимо спричинену ними проблему.'
                    },
                    hedgecheck: {
                        title: 'ЇжаЧек',
                        familyTitle: '... і дозвольте представити мого молодшого "брата", це ',
                        slogan: 'Утиліта для обробки контрольних списків (checklistів) на основі деревовидної структури, дозволяє створювати кілька списків, експортувати/імпортувати їх тощо.',
                        features: [
                            'має деревовидну структуру з довільним рівнем',
                            'імпорт із текстових і csv-файлів',
                            'експорт/імпорт у внутрішній формат "hedgecheck".',
                        ],
                        screenshots:
                            [
                                {
                                    text: 'Головний екран',
                                    type: productsData.get(products.hedgecheck).screenshotsTypes.mainScreen
                                },
                                {
                                    text: 'Головний екран - Редагування спискiв',
                                    type: productsData.get(products.hedgecheck).screenshotsTypes.mainEdit
                                },
                                {
                                    text: 'Приклад контрольного списку',
                                    type: productsData.get(products.hedgecheck).screenshotsTypes.checklist
                                },
                                {
                                    text: 'Створення нового контрольного списку',
                                    type: productsData.get(products.hedgecheck).screenshotsTypes.newChecklist
                                },
                                {
                                    text: 'Редагування контрольного списку',
                                    type: productsData.get(products.hedgecheck).screenshotsTypes.checklistEdit
                                },
                                {
                                    text: 'Eкспорт контрольного списку',
                                    type: productsData.get(products.hedgecheck).screenshotsTypes.export
                                },
                            ],
                        privacyPolicy: {title: 'Політика <br/>конфіденційності <br/>програми "ЇжаЧек"'}
                    }
                },
            },
            ru: {
                translation: {
                    index: {
                        title: '"Ёж-читатель" - читалка электронных книг',
                        familyTitle: '... а этой мой старший брат - ',
                        header: {
                            title: 'Ёж-читатель',
                            slogan: 'Читалка книг для тех, кто ценит время',
                        },
                        menu:
                            [
                                {title: 'Загрузить', type: menuItemTypes.download},
                                {title: 'Что умеет', type: menuItemTypes.features},
                                {title: 'Скриншоты', type: menuItemTypes.screenshots},
                                {title: 'Видео', type: menuItemTypes.videos},
                                {title: 'О нас', type: menuItemTypes.about, newPage: true},
                                {title: 'Политика конфиденциальности', type: menuItemTypes.privacy, newPage: true},
                                {title: 'Жанры FB2', type: menuItemTypes.genres, newPage: true},
                                {title: 'Руководство по миграции', type: menuItemTypes.migrationGuide, newPage: true}
                            ],
                        download: {
                            install: 'Чтобы установить приложение наведите камеру вашего айфона или айпада на QR-код...',
                            or: '...ИЛИ...',
                            visit: '...откройте',
                            page: 'нашу страницу в Аппстор'
                        },
                        features: [
                            'работает с форматами FB2, EPUB, MOBI, RTF, DOCX, HTML, MHT/MHTML, WEB архив (от Apple), CHM, TXT, CBZ (комикс ZIP) и PDF;<0>если PDF-файл имеет текстовый слой, он будет интерпретирован, и книга будет отформатирована как текст. Если нет, то она откроется как набор обычных картинок, как это делается для комиксов;</0>',
                            'проигрывает аудиокниги форматов MP3, M4A и M4B;',
                            'загружает книги с локального девайса, iCloud, Dropbox, серверов подключенных по протоколу SMB, по одной или несколько сразу;',
                            'поддерживает внутреннюю структуру "полок" с возможностью поиска/фильтрования по жанрам/ключевым словами;',
                            'полнотекстовый поиск по всему содержимому библиотеки, включая созданные заметки;',
                            'переводит слова и фразы на выбранный язык;',
                            'дает возможность быстро исправить опечатки в книге;',
                            'читает текст вслух с разными вариантами скорости и управлением из media control;',
                            'дает возможность добавлять закладки и заметки, с последующим экспортом в виде текстового файла;',
                            'дает возможность прямой загрузки книг с  серверов, которые поддерживают OPDS.'
                        ],
                        screenshots:
                            [
                                {
                                    text: 'Экран импорта',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.importer
                                },
                                {
                                    text: 'Полка (\'в режиме плитки\')',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.shelf
                                },
                                {
                                    text: 'Страница с картинкой',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.withImage
                                },
                                {
                                    text: 'Картинка увеличенная',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.withImageExpanded
                                },
                                {
                                    text: 'Страница с таблицей',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.table
                                },
                                {
                                    text: 'Таблица увеличена',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.tableExpanded
                                },
                                {
                                    text: 'Контекстное меню на выделенном тексте',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.translate
                                },
                                {
                                    text: 'Попап с переводом',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.translated
                                },
                                {
                                    text: 'Контекстное меню на тексте с заметкой',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.noteAndMenu
                                },
                                {
                                    text: 'Содержание',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.contents
                                },
                                {
                                    text: 'Заметки с конпками',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.notes
                                },
                                {
                                    text: 'Выбор голоса',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.voiceSelection
                                },
                                {
                                    text: 'Чтение вслух',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.readAloud
                                },
                                {
                                    text: 'Media control в момент чтения вслух',
                                    type: productsData.get(products.hedgehogReader).screenshotsTypes.mediaControl
                                },
                            ],
                        videoSubtitle: '(по-английски)',
                    },
                    privacyPolicy: {
                        title: 'Политика <br/>конфиденциальности <br/>программы <br/>"Ёж-читатель"',
                        paragraphs: [
                            {
                                header: 'Сбор и использование персональных данных',
                                text: 'Мы не собираем никаких других данных, кроме тех, что необоходимы для работы Google analytics. О собственной политике конфиденциальности этого сервиса можно прочитать <0>здесь</0>',
                            },
                            {
                                header: 'Логирование данных и Куки',
                                text: 'Хоть вышеупомянутая информация не является необходимой для работы программы, она может быть использована (только в бесплатной версии) для таргетирования отображаемой рекламы.',
                            },
                            {
                                header: 'Раскрытие информации третьим сторонам',
                                text: 'Предоставляя пользователям приложение мы снимаем с себя всякую ответственность касательно контента, который может быть ими в него загружен.',
                            },
                            {
                                header: 'Изменения в политике конфиденциальности',
                                text: 'Мы оставляем за собой право в будущем изменять политику конфиденциальности. Все последующие изменения будут выложены на этой странице. В связи с этим мы рекомендуем вам время от времени посещать эту страницу. Изменения в политике конфиденциальности вступают в действия с момента опубликования здесь.',
                            },
                        ],
                    },
                    aboutUs: {
                        title: 'О команде <br/>разработчиков <br/>"Ежа-читателя"',
                        we: 'Вот мы какие:',
                        thanks: '(За этот "семейный портрет в интерьере" сердечно благодарим <0>Анну Лаурсон</0>)',
                        comment: 'Просим иметь в виду, что рассматривать будем только электронные письма, связанные с работой приложения.',
                        images: [
                            {type: aboutLinksTypes.email, text: 'Написать нам', alt: 'Иконка электронной почты'},
                            {type: aboutLinksTypes.feedback, text: 'Оставить отзыв', alt: 'Иконка комментария'},
                            {type: aboutLinksTypes.facebook, text: 'Наша страница на Фейсбуке', alt: 'Иконка Фейсбука'},
                            {type: aboutLinksTypes.instagram, text: 'Мы в Инстаграм', alt: 'Иконка Инстарграма'},
                        ],
                    },
                    genres: {
                        title: 'Посмотреть и/или добавить FB2 жанры',
                        code: 'Код',
                        name: 'Название',
                        alias: 'Псевдоним',
                        add: 'Добавить жанр',
                        withParent: 'Как дочерний от',
                        viewTitle: 'Вы выслали нам следующий жанр:',
                        viewParent: 'Как дочерний от ',
                        viewSwitchButton: 'Выслать еще один',
                        legend: ['таким цветом выводятся жанры из FB2 стандарта;', 'таким цветом выводятся дополнительные жанры, которые поддерживает приложение;', 'новые жанры, запрошенные пользователями; недоступно в версии <bold><data></data></bold>, но появится в следующей.'],
                        connectingError: 'У бэкенд-сервера проблемы соединения. Пожалуйста, повторите попытку позже.',
                        info: 'Код жанра может содержать только латинские буквы или знак подчеркивание и быть не короче чем 3 символа.<br/>Он представляет собой значение поля <0></0><2>genre</2><1></1> тэга <0></0><2>title-info</2><1></1> в секции <0></0><2>description</2><1></1> FB2 файла.',
                        total: 'Всего',
                        underApprove: 'В листе ожидания',
                        down: 'Вниз до формы',
                        texts: ['В стандарте FB2 определен обширный набор жанров к которым можно отнести то или иное произведение. Наше приложение неплохо умеет работать с поиском/фильтрацией вашей личной библиотеки по предоставленной информации. Поэтому не стоит игнорировать такую возможность, если не у всех ваших книг проставлен жанр, то имеет смысл это сделать, тем более, что это делается практически двумя-тремя тапами.',
                            'Но жизнь не стоит на месте. К сожалению, стандарт FB2 не развивается (хотя он и так является самым лучшим выбором для форматирования книг), читатели и писатели придумывают новые жанры, находятся старые произведения, которые не совпадают точно ни с одним из предложенных жанров. Что делать?',
                            'Можно "со скрипом" подобрать наиболее близкий жанр из существующих (cписок жанров определенных в стандарте FictionBook 2.1 см. <0>тут</0>), а можно самостоятельно добавить жанр в приложение.',
                            'При добавлении следуют учитывать следующие факторы:',
                            ['новый жанр будет читаться только нашим приложением (хотя, конечно, в будущем может и удасться включить новый жанр в стандарт, но надежд на это мало);',
                                'код жанра может содержать только символы ASCII table 7-bit (английские буквы без пробелов и знаков препинания);',
                                'код жанра может быть любым, в принципе, но лучше придерживаться вида, принятого в FB2 стандарте;',
                                'название жанра будет переводиться на все языки, поддерживаемые приложением, поэтому старайтесь избегать заумных формулировок и "игры слов";',
                                'добавляя жанр на сайте, вы фактически модифицируете код приложения, и если ваш запрос не входит в противоречие с остальными данными, то можете быть уверенными, что ваше изменение войдет в следующую сборку.']],
                        aliases: 'Этот жанр имеет следующие псевдонимы:',
                        oneAlias: 'Этот жанр имеет псевдоним:',
                        genreExists: 'Жанр с кодом \'<0></0>\' уже существует',
                        addNewAlias: 'Нажмите эту кнопку, чтобы добавить псевдоним к жанру',
                    },
                    feedback: {
                        title: 'Написать нам',
                        fromTitle: 'Ваше имя (<0>необязательно</0>):',
                        fromAddress: 'Ваш email (<0>необязательно</0>):',
                        addressReminder: 'Пожалуйста, имейте в виду, что получить от нас ответ вы сможете только при указании электронной почты',
                        addressError: 'Неверный формат email',
                        text: 'Текст (<0>обязательно</0>):',
                        button: 'Выслать!',
                        clear: 'Очистить',
                        cancel: 'Отмена',
                        viewTitle: 'Вы выслали следующее сообщение:',
                        viewFrom: 'От:',
                        viewText: 'Текст:',
                        viewSwitchButton: 'Выслать еще одно',
                        sendingError: 'Бэкенд-сервер не отвечает. Ваше сообщение не отправлено. Пожалуйста, повторите попытку позже.',
                        connectingError: 'Бэкенд-сервер не отвечает. Отправка сообщений невозможна. Пожалуйста, зайдите позже.',
                        tooManyMessages: 'Мы ценим ваше желание оставить отзыв, но в последнее время с вашего IP-адреса поступало слишком много сообщений. Пожалуйста, повторите попытку позже чуть позже',
                    },
                    migrationGuide: {
                        title: 'Руководство по переходу с версии 2.X на версию 3.X',
                        descr: 'Шаги по реализации',
                        steps: [
                            {pre: 'Настройки системы', text: 'Убедитесь, что iCloud Drive включен'},
                            {pre: 'В 2.X', text: 'Включите резервное копирование iCloud'},
                            {
                                pre: 'В 2.X',
                                text: 'совершите операцию с библиотекой (например, переместите книгу с полки на полку)'
                            },
                            {pre: 'В 3.X', text: 'Включите резервное копирование iCloud'},
                            {pre: 'В 3.X', text: 'Нажмите «Восстановить из резервной копии»'},
                            {
                                pre: 'В 3.X',
                                text: 'Вы увидите экран доступных резервных копий, где вам следует выбрать только что созданную.'
                            },
                            {pre: 'В 3.X', text: 'Подтвердите восстановление из резервной копии'},
                        ]
                    },
                    imagesAltTexts: {
                        badge: 'Значок магазина приложений',
                        qrCode: 'QR-код, ведущий на страницу Ежа-читателя в магазине приложений',
                        hedgehogs: 'Рисунок отображающий двух ежей',
                    },
                    links: {
                        topLink: 'наверх',
                        goBack: 'Назад',
                    },
                    notification: {
                        title: 'Уважаемые пользователи! Если приложение не может импортировать какую-то книгу или книга \'странно\' читается вслух...',
                        text: '... пожалуйста, отправляйте нам такие книги по <0>электронной почте</0> (с темой \'Падает при импорте\' — необязательно).',
                        comment: 'Во избежание нарушения авторских прав обязуемся удалить полученные таким образом книги сразу же как только пофиксим вызванную ими проблему.'
                    },
                    hedgecheck: {
                        title: 'ЕжеЧек',
                        familyTitle: '... и позвольте  представить моего младшего «брата»: ',
                        slogan: 'Утилита для работы с чек-листами, основанная на древовидной структуре, позволяет создавать несколько списков, экспортировать/импортировать их и т.д.',
                        features: [
                            'позволяет создавать объекты древовидной структуры с произвольным количеством уровней',
                            'импорт из txt и csv фалов',
                            'эскпорт/импорт во внутренний формат "hedgecheck".',
                        ],
                        screenshots:
                            [
                                {
                                    text: 'Главный экран',
                                    type: productsData.get(products.hedgecheck).screenshotsTypes.mainScreen
                                },
                                {
                                    text: 'Главный экран - редактирование чеклистов',
                                    type: productsData.get(products.hedgecheck).screenshotsTypes.mainEdit
                                },
                                {
                                    text: 'Чеклист',
                                    type: productsData.get(products.hedgecheck).screenshotsTypes.checklist
                                },
                                {
                                    text: 'Создание чеклиста',
                                    type: productsData.get(products.hedgecheck).screenshotsTypes.newChecklist
                                },
                                {
                                    text: 'Редактирование чеклиста',
                                    type: productsData.get(products.hedgecheck).screenshotsTypes.checklistEdit
                                },
                                {
                                    text: 'Экспорт чеклиста',
                                    type: productsData.get(products.hedgecheck).screenshotsTypes.export
                                },
                            ],
                        privacyPolicy: {title: 'Политика <br/>конфиденциальности <br/>программы <br/>"ЕжеЧек"'}
                    }
                },
            },
        },
        lng: 'en', // if you're using a language detector, do not define the lng option
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
    });